.names {
    margin: 3rem auto 4rem auto;
    display: grid;
    text-align: center;
    gap: 20%;
    font-family: "Arvo", cursive;
    width: max-content;
}
  
.song__name {
    color: #5B0888;
    font-size: 300%;
}
  
.artist__name {
    color: #9400FF; 
    font-size: 150%;
}
  
.app__player__container {
    width: 65%;
    background-size: 100% 80%;
    background-repeat: no-repeat;
    -webkit-border-radius: 103px 103px 103px 103px;
    border: 5px solid #141E46; 
    margin: auto;
}

.player__container {
    -webkit-border-radius: 0px 0px 170px 170px;
}

.control__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 34vh;
    -webkit-border-radius: 0px 0px 100px 100px;
    background: linear-gradient(to left, #eac965, #f7b900, #ffc20b);
    padding: 20px;
    border-top: 5px solid #141E46;
    box-shadow: 11px 90px 15px -20px rgba(0,0,0,0.75);
}

.volume-control {
    position: absolute;
    left: 10%; 
    display: flex;
    align-items: center;
}

.volume {
    font-size: 200%; 
}


input[type=range] {
    width: 60%; 
    margin-left: 6%; 
    appearance: none; /* Removs default styles on other browsers */
}
  
input[type=range]::-webkit-slider-runnable-track {
    height: 10px;
    cursor: pointer;
    background: #FFE79B;
}

input[type=range]::-webkit-slider-thumb {
    appearance: none; /* Removs default styles on other browsers */
    cursor: pointer;
    margin-top: -3px;
    width: 20px; 
    height: 20px;
    background: #322653;
    border-radius: 50%; 
} 

.side_icons {
    color: #272829;
    height: 40px;
    width: 40px;
}
  
.side_icons:hover {
    color: #9400FF;
    cursor: pointer;
}
  
.general_icons {
    color: #272829;
    height: 60px;
    width: 60px;
}
  
.general_icons:hover {
    color: #9400FF;
    cursor: pointer;
}
  

/* ============= MEDIA QUERIES (MEDIUM DEVICES (Tablets)) =============*/

@media screen and (max-width: 1024px){

    .app__player__container {
        width: 85%;
    }

    .control__icons {
        gap: 0px;
        padding: 20px;
        padding-top: 3rem;
    }
    
    .volume-control {
        margin-bottom: 6rem;
        left: 3.5rem;
    }
      
}


/* ============= MEDIA QUERIES (SMALL DEVICES (Phones)) =============*/

@media screen and (max-width: 600px) {

    .names {
        position: absolute;
        left: 50%;
        transform: translateX(-50%); 
        top: 5%;
        gap: 0rem;
    }
      
    .song__name {
        font-size: 5vh;
    }
      
    .app__player__container {
        width: 150%;
        height: 30%;
        margin-top: 9rem;
        margin-left: 25%;

        box-shadow: 0 20px 15px 1px rgba(0,0,0,0.75);
    }
    
    .control__icons {
        gap: 5px;
        margin-top: 16vh;
        height: 4rem;
        padding: 20px;
    }
    
    .volume-control {
        left: 4rem;
        margin-top: 3.5rem;
    }
    
    .volume {
        font-size: 1rem;
    }

    input[type=range] {
        width: 3rem;
        margin-left: 0.4rem;
    }
      
    input[type=range]::-webkit-slider-runnable-track {
        height: 5px;
    }
    
    input[type=range]::-webkit-slider-thumb {
        margin-top: -2px;
        width: 10px; 
        height: 10px;
        border-radius: 50%; 
    } 
    
    .side_icons {
        height: 30px;
        width: 30px;
    }
      
    .general_icons {
        height: 40px;
        width: 40px;
    }
} 