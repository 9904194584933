.header_container {
    background: linear-gradient(
        45deg,
        #573B6E,
        #6F4A6B
    );
    width: 100%;
    display: flex;
}

.brand {
    width: 10vh;
    padding: 1vh;
}

/* ============= MEDIA QUERIES (SMALL DEVICES (Phones)) =============*/

@media screen and (max-width: 600px) {
    .brand {
        width: 9vh;
    }
}