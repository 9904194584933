.footer_container {
    width: 100%;
    text-align: center;
    background: linear-gradient(
        45deg,
        #573B6E,
        #6F4A6B
    );
    padding:0.8rem;
}

.footer_container p{
    font-size: 2vh;
    background: linear-gradient(to left, #eac965, #f7b900, #ffc20b);
    -webkit-background-clip: text; 
    color: transparent;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES (Tablets)) =============*/

@media screen and (max-width: 1024px){
    .footer_container p{
        font-size: 1.9vh;
    }
}
