.instrumentals_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.q {
  background-image: url("../../assets/images/Musical-Instruments/guitar/bass_guitar.png");
}

.w {
  background-image: url("../../assets/images/Musical-Instruments/guitar/classic_guitar.png");
}

.e {
  background-image: url("../../assets/images/Musical-Instruments/guitar/guitar1.png");
}

.r {
  background-image: url("../../assets/images/Musical-Instruments/guitar/guitar2.png");
}

.t {
  background-image: url("../../assets/images/Musical-Instruments/guitar/guitar3.png");
}

.y {
  background-image: url("../../assets/images/Musical-Instruments/guitar/guitar4.png");
}




.a {
  background-image: url("../../assets/images/Musical-Instruments/drum/tom1.png"); 
}
  
.s {
  background-image: url("../../assets/images/Musical-Instruments/drum/tom2.png");
}
 
.d {
  background-image: url("../../assets/images/Musical-Instruments/drum/tom4.png");
}

.f {
  background-image: url("../../assets/images/Musical-Instruments/drum/tom3.png");
}
  
.g {
  background-image: url("../../assets/images/Musical-Instruments/drum/snare.png");
}
  
.h {
  background-image: url("../../assets/images/Musical-Instruments/drum/kick.png");
}


  

.z {
  background-image: url("../../assets/images/Musical-Instruments/drum/crash.png");
}

.x {
  background-image: url("../../assets/images/Musical-Instruments/lazer.png");
}

.c {
  background-image: url("../../assets/images/Musical-Instruments/clap.png");
}

.v {
  background-image: url("../../assets/images/Musical-Instruments/reverse.png");
}

.b {
  background-image: url("../../assets/images/Musical-Instruments/violin.png");
}

.n {
  background-image: url("../../assets/images/Musical-Instruments/fart.png");
}
 

.set {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
  overflow: hidden;
  border: 5px solid #141E46;

  box-shadow: 11px 90px 15px -20px rgba(0,0,0,0.75);

  background: linear-gradient(
    45deg,
    #040D12,
    #9D76C1,
    #5B0888
); 
}

.drum {
  position: relative;
  background-color: transparent;
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  width: 7vw;
  height: 14vh;
  font-family: "Arvo", cursive;
  color: transparent;
  border: 3px solid black;
}

.drum::before {
  content: attr(id);
  color: #8DDFCB;
  font-size: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid black;
  border-right: 2px solid black;
  line-height: 1;
}

.pressed {
  opacity: 0.5;
}


/* ============= MEDIA QUERIES (MEDIUM DEVICES (Tablets)) =============*/

@media screen and (max-width: 1024px){
  
  .drum {
    background-size: 100%;
    border: 1px solid black;
  }
  
  .drum::before {
    font-size: 0rem;
  }

}


/* ============= MEDIA QUERIES (SMALL DEVICES (Phones)) =============*/

@media screen and (max-width: 600px) {

  .instrumentals_container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 25rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  }

  .set {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(9, 1fr);
    overflow: hidden;
    border: 5px solid #141E46;
  
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75);
  
    background: linear-gradient(
      45deg,
      #040D12,
      #9D76C1,
      #5B0888
  ); 
  width: 95%;
  height: 4rem;  
  }

  .drum {
    position: relative;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border: 0 solid black;
    width: 100%;
    max-height: fit-content;
  }
  
  .drum::before {
    font-size: 0rem;
  }

} 

